.aboutContainer{
    background-color: #f7f7f7;
    min-height: calc(100vh - 283px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    
}
.aboutSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 2em;
    padding: 0 10vw;
}

.profileTextWrapper{
    align-self: start;
}

.profileTextWrapper h1{
    margin-top: 0;
}

.aboutText{
    position: relative;
    top: -2rem;
    font-weight: 300;
}

.profileImgWrapper{
    justify-self: end;
    align-self: center;
}

.profileImgWrapper img{
    display: block;
    max-width: 500px;
    height: auto;
    border-radius: 50%;
}

.marquee{
    display: flex;
    overflow: hidden;
    --gap: 1rem;
    gap: var(--gap);
    position: relative;
    user-select: none;
}

.marqueeContent{
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    gap: var(--gap)
}
.marqueeContent img{
    width: 5vw;
    max-height: 80%;

}

@keyframes scroll{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(calc(-100% - var(--gap)));
    }
}

.scroll{
    animation: scroll 20s linear infinite;
}


@media screen and (max-width: 1024px) {
    .aboutSection{
        grid-template-columns: 1fr;
        padding: 0 10vw;
    }

    .aboutText{
        top: 0;
    }
    .profileImgWrapper{
        place-self: center;
    }
}
