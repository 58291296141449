.case-links{
    text-decoration: none;
}

.caseNumber{
    font-family: brema;
}

.tag{
    display: inline;
}

.work{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
}

@media screen and (max-width:760px) {
    .work{
        flex-direction: column;
        align-items: flex-start;
    }
    .tag{
        position: relative;
        top: -15px;
    }
}