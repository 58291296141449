.navBarContainer{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2em 10% 1em 10%;
    background-color: #f7f7f7;
}

.logo{
    height: 100%;;
    text-decoration: none;
    font-size: 30px;
    font-weight: bold;
}

.menuIcon{
    display: none;
}

.navItems{
    display: flex;
    flex-direction: row;
}

.nav-links{
    text-decoration: none;
    margin-left: 30px;
    font-size: 20px;
    font-family: Zen Kaku Gothic New;
    font-weight: 600;
    display: block;
}

.nav-links.active{
    background-color: rgba(196, 221, 146, .5);
}

.nav-links:hover{
    background-color: rgba(155, 200, 235, .5);
}




/* mobile and tablet responsive version */
@media screen and (max-width: 1024px){
   .menuIcon{
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
   }


   .menuIcon img{
    height: 40px;
    width: auto;
   }
    
    .navBarItems{
        position: relative;
    }  
}

@media screen and (max-width: 1024px) {
    .navItems{
        background-color: #f7f7f7;
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 100px;
        width: 100%;
        height: calc(100vh - 100px);
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        padding-top: 4em;
        justify-content: flex-start;
        gap: 1.5rem;

    }

    .navItems.active{
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .navItems.active .nav-links{
        float: none;
        display: block;
        text-align: center;
        border-bottom: none;
        font-size: 3rem;
    }
}