.footerContainer{
    position: relative;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 183px;
    padding-left: 7%;
    background-color: #006a38;
    overflow-y: hidden;
}

.footerItem{
    list-style: none;
}

.footer-links{
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    width: 200px;
    padding-top: 4px;
    font-family: Zen Kaku Gothic New;
}

#whiteLine{
    border-bottom: .5px solid white;
    width: 250px;
}
.footer-links img{
    height: 24px;
    width: auto;
    display: inline-block;
    vertical-align:text-bottom;
    filter: invert(98%) sepia(6%) saturate(331%) hue-rotate(239deg) brightness(116%) contrast(94%);
}

#footerIcon{
    margin-right: 24px;
}
.footer-links > span{
    color: white;
    font-weight: 600;
}

.linkArrow{
    float: right;
}