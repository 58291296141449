@font-face {
    font-family: 'Brema';
    src: url('./assets/fonts/brema.ttf');
}

@font-face {
    font-family: 'Zen Kaku Gothic New';
    src: url('./assets/fonts/ZenKakuGothicNew.ttf');
}

*{
    font-family: Zen Kaku Gothic New;
    color: #006a38;
    overflow-x: hidden;
    box-sizing: border-box;
}

div{
    display: block;
}
.pageContainer{
    min-height: calc(100vh - 283px);
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
    position: relative;
    background-color: #f7f7f7;
}

h1{
    font-size: 4rem;
    font-family: brema;
}

